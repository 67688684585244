/**
 * BxSlider v4.1.2 - Fully loaded, responsive content slider
 * http://bxslider.com
 *
 * Written by: Steven Wanderski, 2014
 * http://stevenwanderski.com
 * (while drinking Belgian ales and listening to jazz)
 *
 * CEO and founder of bxCreative, LTD
 * http://bxcreative.com
 */


/** RESET AND LAYOUT
===================================*/

.bx-wrapper {
	position: relative;
	margin: 0 auto;
	width:100%;
	padding: 0;
	*zoom: 1;
}

.bx-wrapper img {
	max-width: 100%;
  display: block;
}

/** THEME
===================================*/

/*.bx-wrapper .bx-viewport {
	-moz-box-shadow: 0 0 5px #ccc;
	-webkit-box-shadow: 0 0 5px #ccc;
	box-shadow: 0 0 5px #ccc;
	border:  5px solid #fff;
	left: -5px;
	background: #fff;

	-webkit-transform: translatez(0);
	-moz-transform: translatez(0);
    	-ms-transform: translatez(0);
    	-o-transform: translatez(0);
    	transform: translatez(0);
}*/

.bx-wrapper .bx-pager,
.bx-wrapper .bx-controls-auto {
	position: absolute;
	bottom: -30px;
	width: 100%;
}

/* LOADER */

.bx-wrapper .bx-loading {
	min-height: 50px;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2000;
}

/* PAGER */
.bx-pager {
}

.bx-pager a {
	display:block;
}

.bx-pager a.rsn_btn01 {width:89px;}
.bx-pager a.rsn_btn02 {width:89px;}
.bx-pager a.rsn_btn03 {width:84px;}
.bx-pager a.rsn_btn04 {width:85px;}
.bx-pager a.rsn_btn05 {width:85px;}

.bx-pager a.active {
}

.bx-pager a.active.rsn_btn01 {background:url(../reason/img/btn_reason01_on.jpg) top center no-repeat;}
.bx-pager a.active.rsn_btn02 {background:url(../reason/img/btn_reason02_on.jpg) top center no-repeat;}
.bx-pager a.active.rsn_btn03 {background:url(../reason/img/btn_reason03_on.jpg) top center no-repeat;}
.bx-pager a.active.rsn_btn04 {background:url(../reason/img/btn_reason04_on.jpg) top center no-repeat;}
.bx-pager a.active.rsn_btn05 {background:url(../reason/img/btn_reason05_on.jpg) top center no-repeat;}

#bx-pager02 {
	padding-left:135px;
}

#bx-pager02 a {
	display:block;
	float:left;
}

#bx-pager02 a.active {
	height:27px;
}

#bx-pager02 a.active.nhbtn01 {width:128px;background:url(../img/nhbtn01_on.png) no-repeat;}
#bx-pager02 a.active.nhbtn02 {width:122px;background:url(../img/nhbtn02_on.png) no-repeat;}
#bx-pager02 a.active.nhbtn03 {width:134px;background:url(../img/nhbtn03_on.png) no-repeat;}
#bx-pager02 a.active.nhbtn04 {width:136px;background:url(../img/nhbtn04_on.png) no-repeat;}
#bx-pager02 a.active.nhbtn05 {width:101px;background:url(../img/nhbtn05_on.png) no-repeat;}
#bx-pager02 a.active.nhbtn06 {width:89px;background:url(../img/nhbtn06_on.png) no-repeat;}

.bx-wrapper .bx-pager {
	text-align: center;
	font-size: .85em;
	font-family: Arial;
	font-weight: bold;
	color: #666;
	padding-bottom:30px;
}

.bx-wrapper .bx-pager .bx-pager-item,
.bx-wrapper .bx-controls-auto .bx-controls-auto-item {
	display: inline-block;
	*zoom: 1;
	*display: inline;
}

.bx-wrapper .bx-pager.bx-default-pager a {
	background: #666;
	display: block;
	outline: 0;
}

.bx-wrapper .bx-pager.bx-default-pager a:hover,
.bx-wrapper .bx-pager.bx-default-pager a.active {
	background: #000;
}

/* DIRECTION CONTROLS (NEXT / PREV) */

.bx-wrapper .bx-prev {
	left:-60px;
}

.bx-wrapper .bx-next {
	right:-60px;
}

.bx-wrapper .bx-prev:hover {
	background-position: 0 0;
}

.bx-wrapper .bx-next:hover {
	background-position:  0 0;
}

.bx-wrapper .bx-controls-direction a {
	position: absolute;
	top: 50%;
	margin-top: -16px;
	outline: 0;
	width: 34px;
	height: 48px;
	text-indent: -9999px;
	z-index: 9999;
}

.bx-wrapper .bx-controls-direction a.disabled {
	display: none;
}

/* AUTO CONTROLS (START / STOP) */

.bx-wrapper .bx-controls-auto {
	text-align: center;
}

.bx-wrapper .bx-controls-auto .bx-start {
	display: block;
	text-indent: -9999px;
	width: 10px;
	height: 11px;
	outline: 0;
	margin: 0 3px;
}

.bx-wrapper .bx-controls-auto .bx-start:hover,
.bx-wrapper .bx-controls-auto .bx-start.active {
	background-position: -86px 0;
}

.bx-wrapper .bx-controls-auto .bx-stop {
	display: block;
	text-indent: -9999px;
	width: 9px;
	height: 11px;
	outline: 0;
	margin: 0 3px;
}

.bx-wrapper .bx-controls-auto .bx-stop:hover,
.bx-wrapper .bx-controls-auto .bx-stop.active {
	background-position: -86px -33px;
}

/* PAGER WITH AUTO-CONTROLS HYBRID LAYOUT */

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-pager {
	text-align: left;
	width: 80%;
}

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-controls-auto {
	right: 0;
	width: 35px;
}

/* IMAGE CAPTIONS */

.bx-wrapper .bx-caption {
	position: absolute;
	bottom: 0;
	left: 0;
	background: #666\9;
	background: rgba(80, 80, 80, 0.75);
	width: 100%;
}

.bx-wrapper .bx-caption span {
	color: #fff;
	font-family: Arial;
	display: block;
	font-size: .85em;
	padding: 10px;
}

.bx-pager{
}

.bx-pager a{
  position:relative;
  display: inline-block;
}
